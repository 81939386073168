
.title-main {
    color: #2D3748;
    font-size: xx-large;
    font-weight: 500;
}

.title-second {
    color: #2D3748;
    font-size: x-large;
    font-weight: 600;
}

@media (max-width: 1280px) {
    .title-second {
        font-size: large;
    }
}

.subtitle-dark {
    color: #2D3748;
    font-size: medium;
    font-weight: 600;

}

.subtitle-main {
    color: #b08838;
    font-size: medium;
    font-weight: 600;

}

.description-medium {
    color: #4A5568;
    font-size: medium;
    font-weight: 400;
    margin-bottom: 5px;
}

.description-small {
    color: #4A5568;
    font-size: small;
    font-weight: 300;
}

.value-main {
    color: #CEBC80;
    font-size: xx-large;
    font-weight: 700;
    margin-top: 10px;
}

.value-second {
    color: #4A5568;
    font-size: medium;
    font-weight: 500;
    margin-top: 10px;
}

.card-dark {
    background-color: #f0f0f0;
    color: #CEBC80;
    border-radius: 10px;
    padding: 40px;
}

.container-sidebar {
    background-color: #ffffff;
    border: 1px solid;
    border-radius: 10px;
    margin: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.container-white {
    background-color: #ffffff;
    border: 1px solid;
    border-radius: 10px;
    margin: 20px 100px 20px 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border: 1px solid;
}

@media (max-width: 1280px) {
    .container-white {
        margin: 20px;
    }
}

.container-no-margin {
    background-color: #ffffff;
    border: 1px solid;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border: 1px solid;
}

.container-nobg {
    border-radius: 10px;
    margin: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border: 1px solid;
}

.text-gain {
    color: green;
    font-weight: 600;

}

.button-container {
    display: flex;
    text-align: end;
    margin-top: 25px;
}

.icon-container {
    display: flex;
    justify-content: center;
    color: red
}

.text-warning {
    color: red;
    font-weight: 500;
}

.ant-menu-item-selected {
    /* background-color: #CEBC80; */
    color: #2D3748 !important;
}

.button-main {
    background-color: #CEBC80;
    color: #2D3748;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    &:hover {
        background-color: #2D3748;
        color: #CEBC80;
    }
}

@keyframes unlock {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.lock-icon {
    animation: unlock 2s infinite;
}


.select-filter {
    max-width: 300px;
    flex-grow: 2;
    width: auto;
  }